<template>
  <div class="page--lipstickbar brands">
    <div class="container-lipstickbar">
      <router-link v-if="getCookies()" :to="`/lipstickbar/home?store_location_id=${getCookies()}`" class="back-to"
        >BACK</router-link
      >
      <h2 class="heading">Find Your Favourite Lipstick</h2>
      <!-- <p class="copywriting">Rekomendasi masker berdasarkan Beauty Profile kamu</p> -->

      <div v-if="isLoadingFinish" class="row">
        <template v-if="$SO.size(lipstickBrands)">
          <div v-for="(brand, key) in lipstickBrands" :key="key" class="col-sm-4">
            <router-link :to="`/lipstickbar/product-catalogue/${brand.brand_id}`" @click.native="selectBrand(brand)">
              <div class="brand">
                <img
                  v-if="brand.image"
                  :src="`${brand.image}`"
                  class="brand__img"
                  onerror="this.src='/static/img/default_img_brand.svg';"
                />
                <img v-else src="/static/img/default_img_brand.svg" class="brand__img" />
              </div>
            </router-link>
          </div>
        </template>
        <template v-else>
          <p class="text-center">Data not found.</p>
        </template>
      </div>
      <div v-else>
        <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
      </div>

      <div class="box">
        <p class="box__paraf">
          <img src="/static/img/lipstickbar/lipstickdesign-left.svg" class="box__paraf__icon box__paraf__icon-left" />
          Tidak dapat menemukan lipstick favoritmu? <br /><span class="bolder">Beauty Advisor</span> akan dengan senang
          hati membantu
          <img src="/static/img/lipstickbar/lipstickdesign-right.svg" class="box__paraf__icon box__paraf__icon-right" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ls from '~/src/modules/local-storage';
const Config = require('~/config/default.env').default;

export default {
  name: 'LipstickbarBrands',
  data() {
    return {
      isLoadingFinish: false,
      lipstickBrands: '',
    };
  },
  created() {
    this.getLipstickBrand();
  },
  methods: {
    getCookies() {
      if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
        return Cookies.get('store_location_id');
      }
    },
    getLipstickBrand() {
      this.isLoadingFinish = false;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(
          `/offline-store/store-cms/lipstick-bar?store_location_id=${
            this.getCookies() ? this.getCookies() : Config.store_id
          }`,
          {
            params: {
              limit: 18,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.lipstickBrands = response.data.data;
          } else {
            this.lipstickBrands = [];
          }
          this.isLoadingFinish = true;
        })
        .catch((error) => {
          this.lipstickBrands = [];
          this.isLoadingFinish = true;
        });
    },
    selectBrand(brand) {
      this.$store.commit('setBrand', brand);
      ls.set('brand-image', brand.image ? brand.image : '');
    },
  },
};
</script>
